<template>
  <div class="user-edit-segments">
    <b-row>
      <b-col cols="7">
        <b-input-group v-if="segments.length">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="search_segment"
            v-model="searchSegment"
            placeholder="Buscar"
            autocomplete="off"
            :disabled="isSubmiting"
          />
          <b-input-group-append v-if="searchSegment.length">
            <b-button
              variant="outline-primary"
              :disabled="isSubmiting"
              @click="searchSegment = ''"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <vue-perfect-scrollbar
          ref="refSegments"
          :settings="perfectScrollbarSettings"
          class="scroll-area"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(segment, ind) in paginatedSegments"
              :key="segment.id"
              :active="segment.selected"
              :disabled="isSubmiting"
              class="d-flex justify-content-between"
              style="padding: 0px; cursor: default"
            >
              <div style="padding: 0.75em">
                <a
                  v-for="(segmented, index) in segment.segmented_name"
                  :key="`${ind}${index}${segment.name}${segmented}`"
                  class="decoration"
                  @click="searchSegment = segmented.link"
                >
                  <!-- eslint-disable vue/no-v-html -->
                  <span
                    class="strong-searched"
                    v-html="segmented.name"
                  ></span><span v-if="index < segment.segmented_name.length - 1"> > </span>
                  <!-- eslint-enable vue/no-v-html -->
                </a>
              </div>
              <div>
                <b-button
                  style="height: 100%"
                  @click="clickSegment(segment)"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>

        <div
          v-if="paginatedSegments.length === quantityToPaginateSegments"
          class="text-right"
          style="margin: 5px"
        >
          <small>
            Exibindo os {{ quantityToPaginateSegments }} primeiros segmentos filtrados*
          </small>
        </div>
      </b-col>

      <b-col cols="4">
        <vue-perfect-scrollbar
          ref="refSegments"
          :settings="perfectScrollbarSettings"
          class="scroll-area user-edit-segments__selected-list"
        >
          <ul class="ul-list">
            <li
              v-for="selected in selectedSegmentsView"
              :key="selected.segmentId + '-'+selected.subSegmentId"
              :class="selected.padding + (selected.new ? ' new' : '') + (selected.level ? ` level-segment level-${selected.level}` : '')"
            >
              <b-button
                variant="flat-secondary"
                class="btn-icon"
                size="sm"
                :disabled="isSubmiting"
                @click="removeSegment(selected)"
              >
                <feather-icon icon="XCircleIcon" />
              </b-button>
              {{ selected.text }}
            </li>
          </ul>
        </vue-perfect-scrollbar>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import SegmentService from '@/services/segmentService'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BFormInput,
  BListGroup,
  BListGroupItem,
  BButton,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BButton,
    BListGroup,
    BListGroupItem,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    VuePerfectScrollbar,
  },
  props: {
    isSubmiting: {
      type: Boolean,
      required: false,
      default: false,
    },
    preferredSegments: {
      type: Array,
      default: null,
    },
    userType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      zoneService: null,

      quantityToPaginateSegments: 200,
      searchSegment: '',
      segments: [],
      segmentsRoots: [],
      selectedSegments: [],
      initialSegments: [],

      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    }
  },
  computed: {
    paginatedSegments() {
      return this.segments.filter(f => f.hide === undefined || f.hide === false)
        .slice(0, this.quantityToPaginateSegments)
        // adiciona o <strong> no termo buscado
        .map(m => ({
          ...m,
          segmented_name: m.segmented_name.map(m2 => ({
            ...m2,
            name: this.searchSegment.length > 0 ? m2.name.replace(new RegExp(this.searchSegment, 'i'), `<strong>${this.searchSegment}</strong>`) : m2.name,
          })),
        }))
    },

    selectedSegmentsView() {
      let { selectedSegments } = this
      selectedSegments = selectedSegments.sort((a, b) => (a.segmentName + a.subSegmentName).localeCompare(b.segmentName + b.subSegmentName))

      const ret = []
      selectedSegments.forEach(element => {
        if (ret.find(f => f.segmentId === element.segmentId) === undefined) {
          ret.push({
            segmentId: element.segmentId,
            subSegmentId: '',
            text: element.segmentName,
            padding: '',
            new: element.new,
            level: element.level,
          })
        }

        if (ret.find(f => f.segmentId === element.segmentId && f.subSegmentId === element.subSegmentId) === undefined) {
          ret.push({
            segmentId: element.segmentId,
            subSegmentId: element.subSegmentId,
            text: element.subSegmentName,
            padding: 'ul-list-padding-1',
            new: element.new,
            level: element.level,
          })
        }
      })

      return ret
    },
  },
  watch: {
    selectedSegments(value) {
      if (this.isLoading) {
        return
      }

      this.$emit('changed', [...new Set(value.map(m => m.segmentId).filter(f => f.toString() !== ''))])
    },

    searchSegment(value) {
      const search = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

      if (search.length > 0) {
        this.segments = this.segments.map(seg => ({
          ...seg,
          hide: seg.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(search) === -1,
        }))
      } else {
        this.segments = this.segments.map(seg => ({
          ...seg,
          hide: false,
        }))
      }
    },
  },
  created() {
    this.initialSegments = this.preferredSegments
    this.segmentService = new SegmentService()
    this.getSegments()
  },
  methods: {
    countSelectedSegments() {
      return [...new Set(this.selectedSegments.map(m => m.segmentId))].length
    },

    highlight(value) {
      const reg = new RegExp(`(${this.searchSegment})`, 'gi')
      return value.replace(reg, '<b>$1</b>')
    },

    setSegments(segments, clear = false, markNewer = true) {
      if (clear) {
        this.selectedSegments = []
      }
      this.initialSegments = segments
      this.setInitialSegments(markNewer)
    },

    setInitialSegments(markNewer = false) {
      this.initialSegments.forEach(element => {
        const find = this.segments.find(f => f.id === parseInt(element, 10))
        if (find) {
          this.selectedSegments.push({
            segmentName: find.name,
            segmentId: find.id,
            subSegmentName: '',
            subSegmentId: '',
            new: markNewer,
            level: this.userType === 'company' ? find.repLevel : find.companyLevel,
            tags: find.tags,
            automaticSearchTags: [],
            fatherAutomaticSearchTags: [],
          })
        }
      })

      this.removeFlagNew()
    },

    removeFlagNew() {
      if (this.timeout !== undefined) {
        clearInterval(this.timeout)
      }
      this.timeout = setTimeout(() => { this.selectedSegments = this.selectedSegments.map(m => ({ ...m, new: false })) }, 2000)
    },

    getSegments() {
      this.segmentService.getAndStoreSegmentsV2List().then(response => {
        // monta o array do segmented_name, para criar o link usado na busca
        this.segments = response.map(m => ({
          ...m,
          segmented_name: m.name.split(' > ').reduce((carry, item) => {
            const previous = carry.map(m2 => (m2.name)).join(' > ')
            carry.push({
              name: item,
              link: `${previous}${previous.length ? ' > ' : ''}${item}`,
            })
            return carry
          }, []),
        }))
        this.setInitialSegments()
        this.isLoading = false
      })
    },

    clickSegment(segment) {
      this.segments = this.segments.map(m => ({
        ...m,
        selected: m.id === segment.id,
      }))

      this.selectedSegments.push({
        segmentName: segment.name,
        segmentId: segment.id,
        subSegmentName: '',
        subSegmentId: '',
        new: true,
        level: this.userType === 'company' ? segment.repLevel : segment.companyLevel,
        tags: '',
        automaticSearchTags: [],
        fatherAutomaticSearchTags: [],
      })
      this.removeFlagNew()
    },

    removeSegment(subSegment) {
      if (subSegment.subSegmentId === '') {
        this.selectedSegments = this.selectedSegments.filter(f => f.segmentId !== subSegment.segmentId)
      } else {
        this.selectedSegments = this.selectedSegments.filter(f => f.segmentId !== subSegment.segmentId || f.subSegmentId !== subSegment.subSegmentId)
      }
    },
  },
}
</script>

<style lang="scss">
.user-edit-segments {
  .strong-searched strong {
    color: #ff0000;
  }
  .ps-container > .ps__rail-x,
  .ps-container > .ps__rail-y {   opacity: 0.6; }

  span b{
    color: #7367f0;
  }

  .list-group-item.active{
    z-index: 0;
  }

  .scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 450px;
  }

  .ul-list {
    list-style-type: none;
    padding-left: 0px;
  }

  ul li{
    transition-property: text-shadow;
    transition-duration: 2s;
    text-shadow: none;
  }

  a.decoration:hover {
    text-decoration: underline;
  }

  ul li.new{
    text-shadow: 0px 0px 5px #7367f0;
  }

  .ul-list-padding-1 {
    padding-left: 20px;
  }

  .ul-list-padding-2 {
    padding-left: 40px;
  }

  .list-group-item {
    cursor: pointer;
  }

  &__selected-list {
    .level-segment {
      text-shadow: 0 0 1px;
    }

    .level-1 {
      color: green;
    }

    .level-2 {
      color: gold;
    }

    .level-3 {
      color: orangered;
    }

    .level-4 {
      color: red;
    }
  }
}
</style>
